<template>
    <div :before-change="validationForm">
      <validation-observer ref="accountRules" tag="form">
        <!-- <b-card :title="'Change Password: ' + userData.firstname + userData.lastname"> -->
        <b-card :title="$t('Change_Vendor_Password.Change_Password') + userData.firstname + userData.lastname">
          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>
              <!-- Field: new password -->
              <b-col md="6">
                <b-form-group :label="$t('Change_Vendor_Password.New_Password')" label-for="password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|password"
                  >
                    <b-form-input
                      id="password"
                      autocomplete="pass"
                      v-model="userPasswordData.password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- confirm password -->
              <b-col md="6">
                <b-form-group :label="$t('Change_Vendor_Password.Confirm_Password')" label-for="c-password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password Confirm"
                    rules="required|confirmed:Password"
                  >
                    <b-form-input
                      id="c-password"
                      autocomplete="c-pass"
                      v-model="userPasswordData.password_confirmation"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Re-type Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Old Password -->
              <b-col md="6">
                <b-form-group :label="$t('Change_Vendor_Password.Old_Password')" label-for="old-password">
                  <validation-provider
                    #default="{ errors }"
                    name="old-Password"
                    vid="old-Password"
                    rules="required"
                  >
                    <b-form-input
                      id="old-password"
                      autocomplete="pass-old"
                      v-model="userPasswordData.old_password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="validationForm"
            >
            {{ $t("Change_Vendor_Password.Submit") }}
           
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reloadPage"
            >  {{ $t("Change_Vendor_Password.Cancel") }}

              
            </b-button>
          </b-form>
        </b-card>
      </validation-observer>
      <!-- handle error -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul v-for="(values, index) in errors_back" :key="index">
            <li v-for="(value, indexVal) in values" :key="indexVal">
              {{ value }}
            </li>
          </ul>
        </div>
      </b-alert>
    </div>
  </template>
  
  <script>
  
  import { required, password } from '@validations'
  
  export default {
  
    
    data() {
      return {
        id: null,
        showDismissibleAlert: false,
        errors_back: [],
        userPasswordData: {
          password: '',
          old_password: '',
          password_confirmation: '',
        },
        
  
        showDismissibleAlert: false,
        required,
        password,
      }
    },
    created() {
      if (localStorage.getItem('userData') != null) {

        const decryptedText = this.CryptoJS.AES.decrypt(
          localStorage.getItem("userData"),
          "EncryptDataMobileMasr"
        ).toString(this.CryptoJS.enc.Utf8);

        this.userData = JSON.parse(decryptedText);
      }
    },
    methods: {
      // fetch data od admin
      reloadPage() {
        this.$router.back(1)
      },
      // submit updates
      validationForm() {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            
              axios
                .post('password/change' , this.userPasswordData)
                .then((result) => {
                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.$router.back(1)
                })
                .catch((err) => {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back.length = 0
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      })
                    } else {
                      this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                  } else {
                    this.errors_back = []
                    this.errors_back.push({
                      error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                  }
                })
            
          }
        })
      },
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
  
  <style lang="scss">
  
  </style>
  